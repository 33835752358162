// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// Plugins
import { roundNumber } from '../../../../../plugins/general';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';

// Components
import {
  LineChart,
  LineChartType,
  Series,
} from '../../../../../layout/elements/LineChart';

// View
import { TrendingsLubeUnitProps } from './TrendingsLubeUnit.types';

type FilteredData = {
  supplyTemp: number | null;
  returnTemp: number | null;
  tankTemp: number | null;
  lubePressure: number | null;
  readAt: number | null;
}[];

function TrendingsLubeUnit({
  className,
  testId,
  unitData,
}: TrendingsLubeUnitProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const series: Series[] = [
    {
      name: t('view.crusher.trendings.lubeUnit.legend.supplyTemp'),
      field: 'supplyTemp',
      type: LineChartType.line,
      date: 'readAt',
      unit: '°C',
      decimals: 2,
      bullet: true,
    },
    {
      name: t('view.crusher.trendings.lubeUnit.legend.returnTemp'),
      field: 'returnTemp',
      type: LineChartType.line,
      date: 'readAt',
      unit: '°C',
      decimals: 2,
      bullet: true,
    },
    {
      name: t('view.crusher.trendings.lubeUnit.legend.tankTemp'),
      field: 'tankTemp',
      type: LineChartType.line,
      date: 'readAt',
      unit: '°C',
      decimals: 2,
      bullet: true,
    },
    {
      name: t('view.crusher.trendings.lubeUnit.legend.lubePressure'),
      field: 'lubePressure',
      type: LineChartType.line,
      date: 'readAt',
      unit: 'psi',
      opposite: true,
      bullet: true,
    },
  ];

  const filteredData: FilteredData = !_.isNil(unitData)
    ? unitData.map((data: EquipmentUnitDataCrusher) => ({
      supplyTemp: roundNumber(data.lubricationSupplyTemperature, 2),
      returnTemp: roundNumber(data.lubricationReturnTemperature, 2),
      tankTemp: roundNumber(data.lubricationTankTemperature, 2),
      lubePressure: roundNumber(data.lubricationOilPressure, 2),
      readAt: data.readAt,
    }))
    : [];

  return (
    <LineChart
      testId={testId}
      className={className}
      id="lube-unit-chart"
      series={series}
      data={filteredData}
      timeUnit="second"
      activeBullet
    />
  );
}

export { TrendingsLubeUnit };

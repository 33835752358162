// Core
import styled from 'styled-components';

// Libraries
import { Pagination as PaginationAntD } from 'antd';

export const Container = styled(PaginationAntD)`
  .ant-pagination-item a {
    transition: none;
  };

  .ant-pagination-item,
  .ant-pagination-item:hover {
    border-radius: 50%;
    line-height: 32px;
  };

  .ant-pagination-item-active {
    border: none;
  };

  .ant-pagination-item-active a {
    border-radius: 50%;
    color: ${({ theme }) => theme.color.pagination.colorPageDefault};
  };

  .ant-pagination-prev,
  .ant-pagination-item-link,
  .ant-pagination-next {
    border-radius: 50%;
  };

  .ant-pagination-jump-prev,
  .ant-pagination-item-container,
  .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next {
    color: ${({ theme }) => theme.color.pagination.colorEllipsis};
  };
`;

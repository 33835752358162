// Core
import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="#717680"
    >
      <path d="M11 13.75L7.1106 9.86062L8.40768 8.56445L11 11.1577L13.5923 8.56445L14.8894 9.86062L11 13.75Z" />
    </svg>
  );
}

export default Icon;

/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled, { css } from 'styled-components';

// Libraries
import { Button } from 'antd';
import { OptionsSelectProps } from '../OptionsSelect';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .ant-btn.ant-btn-block {
    width: fit-content;
  };
`;

export const Element = styled.div`
  position: relative;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
  };

  & > ul {
    bottom: 40px;
  };
`;

export const Select = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  left: 0;
    

  & > li {
    padding: 16px;

    & > svg {
      display: none;
    };
  };
`;

export const PaginationButton = styled(Button)<Pick<OptionsSelectProps, 'open'>>`
  ${({ theme, open }) => css`
    height: fit-content;
    gap: 8px;
    padding: 4px 4px 4px 10px;
    background: ${theme.color.paginationSizeSelector.backgroundButtonColor};
    border-radius: 4px;
    color: ${theme.color.paginationSizeSelector.valueColor};
    transition: all 0.3s ease-in-out;
    border: 1px solid ${
      open
        ? theme.color.paginationSizeSelector.borderColorHover
        : 'transparent'
    };

    & > span > svg {
      transform: ${!open ? 'rotate(0)' : 'rotate(180deg)'};
      margin: 0;
      transition: all 0.3s ease-in-out;
      fill: ${
        open
          ? theme.color.paginationSizeSelector.currentPageSelectedColor
          : theme.color.paginationSizeSelector.currentPageColor
      };
    };

    & > span {
      transition: all 0.3s ease-in-out;
      color: ${
        open
          ? theme.color.paginationSizeSelector.currentPageSelectedColor
          : theme.color.paginationSizeSelector.currentPageColor
      };
    };

    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: ${theme.color.paginationSizeSelector.backgroundButtonColor};
      border: 1px solid ${theme.color.paginationSizeSelector.borderColorHover};
    };
  `};
`;

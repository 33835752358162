// Libraries
import _ from 'lodash';

// Plugins
import { DASHBOARD_NULLISH_TEXT } from './general';

// Locale
import i18n from '../locale/i18n';

// Types
import {
  CrusherFeederAutomationMode,
  CrusherMotorControl,
  CrusherOperationMode,
  CrusherOverallStatus,
  CrusherRunningStatus,
  CrusherWeatherModeStatus,
} from '../types/crusher';
import { EdgeDeviceOperationalStatus, EquipmentAlarmThresholds } from '../types/global';

// Components
import { StatusIndicatorStatus } from '../layout/elements/StatusIndicator';
import { TagType } from '../layout/elements/Tag';
import { TagStatus } from '../components/EquipmentStatusTag';

/**
 * Converts the overall status of the crusher to a localized text string.
 *
 * @param {CrusherOverallStatus | null} [overallStatus] - The overall status of the crusher.
 * @returns {string} - The localized text string corresponding to the crusher's overall status.
 */
export const overallStatusToText = (
  overallStatus: CrusherOverallStatus | null | undefined,
): string => {
  switch (overallStatus) {
    case CrusherOverallStatus.good:
      return i18n.t('common.good');
    case CrusherOverallStatus.warning:
      return i18n.t('common.warning');
    case CrusherOverallStatus.alarm:
      return i18n.t('common.alarm.singular');
    default:
      return DASHBOARD_NULLISH_TEXT;
  }
};

/**
 * Converts the overall status of the crusher to a TagType.
 *
 * @param {CrusherOverallStatus | null} [overallStatus] - The overall status of the crusher.
 * @param {EdgeDeviceOperationalStatus} operationalStatus - The operational status of the
 * crusher's edge device.
 * @returns {TagType} - The TagType corresponding to the crusher's overall status.
 */
export const overallStatusToTagType = (
  overallStatus: CrusherOverallStatus | null | undefined,
  operationalStatus: EdgeDeviceOperationalStatus | null,
): TagType => {
  if (operationalStatus !== EdgeDeviceOperationalStatus.on) {
    return TagType.neutral;
  }

  switch (overallStatus) {
    case CrusherOverallStatus.good:
      return TagType.success;
    case CrusherOverallStatus.warning:
      return TagType.warning;
    case CrusherOverallStatus.alarm:
      return TagType.critical;
    default:
      return TagType.neutral;
  }
};

/**
 * Converts the permissive status to a localized text string.
 *
 * @param {boolean | null} [permissiveStatus] - The permissive status.
 * @returns {string} - The localized text string corresponding to the permissive status.
 */
export const permissiveStatusToText = (permissiveStatus: boolean | null | undefined): string => {
  switch (permissiveStatus) {
    case true:
      return i18n.t('common.fault');
    case false:
      return i18n.t('common.good');
    default:
      return DASHBOARD_NULLISH_TEXT;
  }
};

/**
 * Converts the edge device operational status and permissive status to a TagType.
 *
 * @param {EdgeDeviceOperationalStatus} operationalStatus - The operational status of the
 * edge device.
 * @param {boolean | null} [permissiveStatus] - The permissive status.
 * @returns {TagType} - The TagType corresponding to the edge device operational status and
 * permissive status.
 */
export const permissiveStatusToTagType = (
  permissiveStatus: boolean | null | undefined,
  operationalStatus: EdgeDeviceOperationalStatus | null,
): TagType => {
  if (operationalStatus !== EdgeDeviceOperationalStatus.on) {
    return TagType.neutral;
  }

  switch (permissiveStatus) {
    case true:
      return TagType.critical;
    case false:
      return TagType.success;
    default:
      return TagType.neutral;
  }
};

/**
 * Converts the edge device operational status and permissive status to a StatusIndicatorStatus.
 *
 * @param {EdgeDeviceOperationalStatus} operationalStatus - The operational status of the
 * edge device.
 * @param {boolean | null} [permissiveStatus] - The permissive status.
 * @returns {StatusIndicatorStatus} - The StatusIndicatorStatus corresponding to the edge device
 * operational status and permissive status.
 */
export const permissiveStatusToIndicatorStatus = (
  permissiveStatus: boolean | null | undefined,
  operationalStatus: EdgeDeviceOperationalStatus | null,
): StatusIndicatorStatus => {
  if (operationalStatus !== EdgeDeviceOperationalStatus.on) {
    return StatusIndicatorStatus.neutral;
  }

  switch (permissiveStatus) {
    case true:
      return StatusIndicatorStatus.alarm;
    case false:
      return StatusIndicatorStatus.good;
    default:
      return StatusIndicatorStatus.neutral;
  }
};

/**
 * Converts the crusher operation mode to a localized text string.
 *
 * @param {CrusherOperationMode | null} [operationMode] - The operation mode of the crusher.
 * @returns {string} - The localized text string corresponding to the crusher's operation mode.
 */
export const operationModeToText = (
  operationMode: CrusherOperationMode | null | undefined,
): string => {
  switch (operationMode) {
    case CrusherOperationMode.local:
      return i18n.t('common.local');
    case CrusherOperationMode.remote:
      return i18n.t('common.remote');
    case CrusherOperationMode.service:
      return i18n.t('common.service');
    default:
      return DASHBOARD_NULLISH_TEXT;
  }
};

/**
 * Converts the vibration sensor value to a localized text string based on the provided thresholds.
 *
 * @param {ThresholdsType} thresholds - The thresholds for the vibration sensor.
 * @param {number | null} [vibrationSensor] - The current value of the vibration sensor.
 * @returns {string} - The localized text string corresponding to the vibration sensor's status.
 */
export const vibrationSensorToText = (
  vibrationSensor: number | null,
  thresholds: EquipmentAlarmThresholds,
): string => {
  if (_.isNil(vibrationSensor)) {
    return DASHBOARD_NULLISH_TEXT;
  }

  if (!_.isNil(thresholds.highWarning) && vibrationSensor > thresholds.highWarning) {
    return i18n.t('common.warning');
  }

  return i18n.t('common.ok');
};

/**
 * Converts the edge device operational status and vibration sensor value to a TagType.
 *
 * @param {EdgeDeviceOperationalStatus} operationalStatus - The operational status of the
 * edge device.
 * @param {ThresholdsType} thresholds - The thresholds for the vibration sensor.
 * @param {number | null} [vibrationSensor] - The current value of the vibration sensor.
 * @returns {TagType} - The TagType corresponding to the edge device operational status and
 * vibration sensor value.
 */
export const vibrationSensorToTagType = (
  vibrationSensor: number | null,
  thresholds: EquipmentAlarmThresholds,
  operationalStatus: EdgeDeviceOperationalStatus | null,
): TagType => {
  if (_.isNil(vibrationSensor) || operationalStatus !== EdgeDeviceOperationalStatus.on) {
    return TagType.neutral;
  }

  if (!_.isNil(thresholds.highWarning) && vibrationSensor > thresholds.highWarning) {
    return TagType.warning;
  }

  return TagType.success;
};

/**
 * Converts the running status of the crusher to a localized text string.
 *
 * @param {CrusherRunningStatus | null} [runningStatus] - The running status of the crusher.
 * @returns {string} - The localized text string corresponding to the crusher's running status.
 */
export const runningStatusToText = (
  runningStatus: CrusherRunningStatus | null | undefined,
): string => {
  switch (runningStatus) {
    case CrusherRunningStatus.running:
      return i18n.t('common.running');
    case CrusherRunningStatus.starting:
      return i18n.t('common.starting');
    case CrusherRunningStatus.fault:
      return i18n.t('common.fault');
    case CrusherRunningStatus.off:
      return i18n.t('common.off');
    default:
      return DASHBOARD_NULLISH_TEXT;
  }
};

/**
 * Converts the running status of the crusher and the edge device operational status to a TagStatus.
 *
 * @param {CrusherRunningStatus | null} runningStatus - The running status of the crusher.
 * @param {EdgeDeviceOperationalStatus | null} edgeDeviceOperationalStatus - Status of the edge
 * device.
 * @returns {TagStatus} - The TagStatus corresponding to the [EquipmentStatusTag].
 */
export const runningStatusToEquipmentStatus = (
  runningStatus: CrusherRunningStatus | null | undefined,
  edgeDeviceOperationalStatus: EdgeDeviceOperationalStatus | null,
): TagStatus => {
  if (edgeDeviceOperationalStatus !== EdgeDeviceOperationalStatus.on || _.isNil(runningStatus)) {
    return TagStatus.stopped;
  }

  switch (runningStatus) {
    case CrusherRunningStatus.running:
      return TagStatus.running;
    case CrusherRunningStatus.off:
      return TagStatus.stopped;
    default:
      return TagStatus.stopped;
  }
};

/**
 * Converts the edge device operational status and running status to a TagType.
 *
 * @param {EdgeDeviceOperationalStatus} operationalStatus - The operational status of the
 * edge device.
 * @param {CrusherRunningStatus | null} [runningStatus] - The running status of the crusher.
 * @returns {TagType} - The TagType corresponding to the edge device operational status and
 * running status.
 */
export const runningStatusToTagType = (
  runningStatus: CrusherRunningStatus | null | undefined,
  operationalStatus: EdgeDeviceOperationalStatus | null,
): TagType => {
  if (operationalStatus !== EdgeDeviceOperationalStatus.on || _.isNil(runningStatus)) {
    return TagType.neutral;
  }

  switch (runningStatus) {
    case CrusherRunningStatus.running:
      return TagType.success;
    case CrusherRunningStatus.starting:
      return TagType.warning;
    case CrusherRunningStatus.fault:
      return TagType.critical;
    case CrusherRunningStatus.off:
      return TagType.neutral;
    default:
      return TagType.neutral;
  }
};

/**
 * Converts the edge device operational status and running status to a StatusIndicatorStatus.
 *
 * @param {EdgeDeviceOperationalStatus} operationalStatus - Edge Device status [on/off].
 * @param {CrusherRunningStatus | null} [runningStatus] - The running status of the crusher.
 * @returns {StatusIndicatorStatus} - StatusIndicatorStatus is the [status] of the StatusIndicator.
 */
export const runningStatusToIndicatorStatus = (
  runningStatus: CrusherRunningStatus | null | undefined,
  operationalStatus: EdgeDeviceOperationalStatus | null,
): StatusIndicatorStatus => {
  if (operationalStatus !== EdgeDeviceOperationalStatus.on || _.isNil(runningStatus)) {
    return StatusIndicatorStatus.neutral;
  }

  switch (runningStatus) {
    case CrusherRunningStatus.running:
      return StatusIndicatorStatus.good;
    case CrusherRunningStatus.starting:
      return StatusIndicatorStatus.warning;
    case CrusherRunningStatus.fault:
      return StatusIndicatorStatus.alarm;
    case CrusherRunningStatus.off:
      return StatusIndicatorStatus.neutral;
    default:
      return StatusIndicatorStatus.neutral;
  }
};

/**
 * Converts the running status of the countershaft and the edge device operational status to
 * a StatusIndicatorStatus.
 *
 * @param {boolean | null} runningStatus - The running status of the countershaft.
 * @param {EdgeDeviceOperationalStatus | null} operationalStatus - The operational status of the
 * edge device.
 * @returns {StatusIndicatorStatus} - The StatusIndicatorStatus corresponding to the
 * edge device operational status and running status.
 */
export const runningStatusToCountershaftIndicator = (
  runningStatus: boolean | null | undefined,
  operationalStatus: EdgeDeviceOperationalStatus | null,
): StatusIndicatorStatus => {
  if (operationalStatus !== EdgeDeviceOperationalStatus.on || _.isNil(runningStatus)) {
    return StatusIndicatorStatus.neutral;
  }

  return runningStatus ? StatusIndicatorStatus.good : StatusIndicatorStatus.alarm;
};

/**
 * Converts the weather mode status of the crusher to a localized text string.
 *
 * @param {CrusherWeatherModeStatus | null} [weatherMode] - The weather mode status of the crusher.
 * @returns {string} - The localized text string corresponding to the crusher's weather mode status.
 */
export const weatherModeStatusToText = (
  weatherMode: CrusherWeatherModeStatus | null | undefined,
): string => {
  switch (weatherMode) {
    case CrusherWeatherModeStatus.on:
      return i18n.t('common.on');
    case CrusherWeatherModeStatus.off:
      return i18n.t('common.off');
    default:
      return DASHBOARD_NULLISH_TEXT;
  }
};

/**
 * Converts the edge device operational status and weather mode status to a TagType.
 *
 * @param {EdgeDeviceOperationalStatus} operationalStatus - Edge Device status [on/off].
 * @param {CrusherWeatherModeStatus | null} [weatherMode] - The weather mode status of the crusher.
 * @returns {TagType} - The TagType is the [type] of the Tag.
 */
export const weatherModeStatusToTagType = (
  weatherMode: CrusherWeatherModeStatus | null | undefined,
  operationalStatus: EdgeDeviceOperationalStatus | null,
): TagType => {
  if (operationalStatus !== EdgeDeviceOperationalStatus.on || _.isNil(weatherMode)) {
    return TagType.neutral;
  }

  switch (weatherMode) {
    case CrusherWeatherModeStatus.on:
      return TagType.success;
    case CrusherWeatherModeStatus.off:
      return TagType.critical;
    default:
      return TagType.neutral;
  }
};

/**
 * Converts the control mode of the crusher motor to a localized text string.
 *
 * @param {CrusherMotorControl | null} [controlMode] - The control mode of the crusher motor.
 * @returns {string} - The localized text string corresponding to the crusher motor's control mode.
 */
export const controlModeToText = (
  controlMode: CrusherMotorControl | null | undefined,
): string => {
  switch (controlMode) {
    case CrusherMotorControl.auto:
      return i18n.t('common.auto');
    case CrusherMotorControl.manual:
      return i18n.t('common.manual');
    default:
      return DASHBOARD_NULLISH_TEXT;
  }
};

/**
 * Converts the edge device operational status and control mode to a TagType.
 *
 * @param {EdgeDeviceOperationalStatus} operationalStatus - Edge Device status [on/off].
 * @param {CrusherMotorControl | null} [controlMode] - The control mode of the crusher motor.
 * @returns {TagType} - The TagType is the [type] of the Tag.
 */
export const controlModeToTagType = (
  controlMode: CrusherMotorControl | null | undefined,
  operationalStatus: EdgeDeviceOperationalStatus | null,
): TagType => {
  if (operationalStatus !== EdgeDeviceOperationalStatus.on || _.isNil(controlMode)) {
    return TagType.neutral;
  }

  switch (controlMode) {
    case CrusherMotorControl.auto:
      return TagType.success;
    case CrusherMotorControl.manual:
      return TagType.neutral;
    default:
      return TagType.neutral;
  }
};

/**
 * Converts the feeder automation mode of the crusher to a localized text string.
 *
 * @param {CrusherFeederAutomationMode | null} [status] - The feeder automation mode of the crusher
 * @returns {string} - The localized text string corresponding to the crusher's feeder
 * automation mode.
 */
export const feederAutomationModeToTagType = (
  status: CrusherFeederAutomationMode | null,
  operationalStatus: EdgeDeviceOperationalStatus | null,
): TagType => {
  if (operationalStatus !== EdgeDeviceOperationalStatus.on || _.isNil(status)) {
    return TagType.neutral;
  }

  switch (status) {
    case CrusherFeederAutomationMode.auto:
      return TagType.success;
    case CrusherFeederAutomationMode.manual:
    default:
      return TagType.neutral;
  }
};

/**
 * Converts the feeder automation mode of the crusher to a localized text string.
 *
 * @param {CrusherFeederAutomationMode | null} [status] - The feeder automation mode of the crusher
 * @returns {string} - The localized text string corresponding to the crusher's feeder
 * automation mode.
 */
export const feederAutomationModeToText = (
  status: CrusherFeederAutomationMode | null,
): string => {
  switch (status) {
    case CrusherFeederAutomationMode.auto:
      return i18n.t('common.auto');
    case CrusherFeederAutomationMode.manual:
      return i18n.t('common.manual');
    default:
      return DASHBOARD_NULLISH_TEXT;
  }
};

/**
 * Converts the permissive status to a localized text string.
 *
 * @param {boolean | null} permissive - The permissive status.
 * @returns {string} - The localized text string corresponding to the permissive status.
 */
export const permissiveToOnOffText = (permissive: boolean | null | undefined): string => {
  switch (permissive) {
    case true:
      return i18n.t('common.off');
    case false:
      return i18n.t('common.on');
    default:
      return DASHBOARD_NULLISH_TEXT;
  }
};

/**
 * Converts the permissive status and operational status of the edge device to a TagType.
 *
 * @param {boolean | null} permissive - The permissive status.
 * @param {EdgeDeviceOperationalStatus} operationalStatus - The status of the edge device.
 * @returns {TagType} - The TagType is the [type] of the Tag.
 */
export const permissiveToOnOffTagType = (
  permissive: boolean | null | undefined,
  operationalStatus: EdgeDeviceOperationalStatus | null,
): TagType => {
  if (operationalStatus !== EdgeDeviceOperationalStatus.on || _.isNil(permissive)) {
    return TagType.neutral;
  }

  switch (permissive) {
    case true:
      return TagType.critical;
    case false:
      return TagType.success;
    default:
      return TagType.neutral;
  }
};

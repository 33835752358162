// Core
import React, { useRef } from 'react';

// Libraries
import { useTheme } from 'styled-components';

// Components
import { Check } from '../../icons';
import { Paragraph, ParagraphSize, ParagraphWeight } from '../Paragraph';

// Component
import { OptionSelect, OptionsSelectMode, OptionsSelectProps } from './OptionsSelect.types';
import { Container, OptionsList } from './OptionsSelect.style';

// Hooks
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

function OptionsSelect({
  className,
  mode = OptionsSelectMode.default,
  onClose,
  onSelect,
  open = false,
  options,
  selected,
  testId,
}: OptionsSelectProps): JSX.Element {
  const theme = useTheme();
  const containerRef = useRef<HTMLUListElement>(null);
  const isMultiple: boolean = mode === OptionsSelectMode.multiple;

  // Checks if an option is selected
  const isSelected = (key: string | number): boolean => (
    Array.isArray(selected)
      ? selected.includes(key)
      : selected === key
  );

  // Handles single selection
  const handleSingleSelection = (key: string | number): string | number => key;

  // Handles multiple selection
  const handleMultipleSelection = (key: string | number): OptionsSelectProps['selected'] => {
    let selectedArray: (string | number)[] = [];

    if (Array.isArray(selected)) {
      selectedArray = selected;
    } else if (selected !== null) {
      selectedArray = [selected];
    }

    return selectedArray.includes(key)
      ? selectedArray.filter((selectedKey) => selectedKey !== key)
      : [...selectedArray, key];
  };

  // Toggles the selection of an option
  const toggleSelection = (key: string | number): void => {
    const newSelected = isMultiple ? handleMultipleSelection(key) : handleSingleSelection(key);
    onSelect(newSelected);
  };

  // Closes the optionsSelect when clicking outside
  useOnClickOutside(containerRef, () => {
    onClose(false);
  });

  return (
    <Container
      className={className}
      data-testid={testId}
      open={open}
      ref={containerRef}
    >
      {options.map(({ key, text }: OptionSelect) => (
        <OptionsList
          key={key}
          onClick={() => {
            toggleSelection(key);
            if (!isMultiple) {
              onClose(false);
            }
          }}
          value={text}
        >
          <Paragraph
            size={ParagraphSize.sm}
            weight={isSelected(key) ? ParagraphWeight.bold : ParagraphWeight.regular}
            color={theme.color.optionsSelect.optionTextColor}
          >
            {text}
          </Paragraph>
          {isSelected(key) && <Check />}
        </OptionsList>
      ))}
    </Container>
  );
}

export { OptionsSelect };

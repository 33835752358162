// Core
import React, {
  useState,
  useEffect, useCallback,
} from 'react';

// Component
import { PaginationFooterProps } from './PaginationFooter.types';
import {
  Border,
  Container,
  Content,
} from './PaginationFooter.style';

// Layout
import { Pagination } from '../Pagination';
import { PaginationSizeSelector } from '../PaginationSizeSelector';

function PaginationFooter({
  className,
  currentPage,
  onPageChange,
  pageSizeIndexDefault = 0,
  pageSizeOptions = [10, 20, 50, 100],
  testId,
  totalItems,
}: PaginationFooterProps): JSX.Element {
  /* ********** Dependencies **********  */

  // Validating the default page size index
  const validatedPageSizeIndexDefault: number = (
    (pageSizeIndexDefault >= 0 && pageSizeIndexDefault < pageSizeOptions.length)
      ? pageSizeIndexDefault
      : pageSizeOptions.length - 1
  );

  /* ***********************************************************************************************
  ************************************ INITIAL STATES **********************************************
  *********************************************************************************************** */

  const initialState: {
    pageSize: number,
  } = {
    pageSize: pageSizeOptions[validatedPageSizeIndexDefault],
  };

  const [state, setState] = useState(initialState);

  /* ***********************************************************************************************
  ***************************************** METHODS ************************************************
  *********************************************************************************************** */

  const handlePageSizeChange = useCallback((newPageSize: number): void => {
    if (state.pageSize !== newPageSize) {
      setState({ pageSize: newPageSize });
    }
  }, [state.pageSize]);

  /* ***********************************************************************************************
  ************************************* COMPONENT HANDLING *****************************************
  *********************************************************************************************** */

  useEffect(() => {
    onPageChange(currentPage, state.pageSize);
  }, [
    currentPage,
    onPageChange,
    state.pageSize,
  ]);

  return (
    <Container className={className} testId={testId}>
      <Border />
      <Content>
        <PaginationSizeSelector
          onSelectPageSize={handlePageSizeChange}
          pageSizeOptions={pageSizeOptions}
          pageSizeIndexDefault={validatedPageSizeIndexDefault}
          testId="pagination-size-selector"
          totalItems={totalItems}
        />
        <Pagination
          current={currentPage}
          onPageChange={onPageChange}
          pageSize={state.pageSize}
          testId="pagination"
          totalItems={totalItems}
        />
      </Content>
    </Container>
  );
}

export { PaginationFooter };

// Core
import { useEffect, useRef, useState } from 'react';

// Libraries
import {
  io,
  ManagerOptions,
  Socket,
  SocketOptions,
} from 'socket.io-client';

export interface SocketHookPayload {
  url: string;
  token?: string;
}

export enum SocketDefaultListenEvents {
  connect = 'connect',
  disconnect = 'disconnect',
}

const makeSocketConnection = (payload: SocketHookPayload): Socket => {
  const connectionOptions: Partial<ManagerOptions & SocketOptions> = !payload.token ? {} : {
    auth: {
      authToken: payload.token,
    },
  };
  return io(payload.url, {
    ...connectionOptions,
    autoConnect: false,
  });
};

export const useSocket = (payload: SocketHookPayload): {
  socket: Socket | null;
  isConnected: boolean;
} => {
  const socket = useRef<Socket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  useEffect(() => {
    if (socket.current === null) {
      socket.current = makeSocketConnection(payload);
    }
  }, [socket, payload]);

  useEffect(() => {
    const onConnect = (): void => {
      setIsConnected(true);
    };

    const onDisconnect = (): void => {
      setIsConnected(false);
    };

    if (socket.current) {
      socket.current.on(SocketDefaultListenEvents.connect, onConnect);
      socket.current.on(SocketDefaultListenEvents.disconnect, onDisconnect);
    }

    return () => {
      if (socket.current) {
        socket.current.off(SocketDefaultListenEvents.connect, onConnect);
        socket.current.off(SocketDefaultListenEvents.disconnect, onDisconnect);
        socket.current.disconnect();
      }
    };
  }, [socket]);

  return {
    socket: socket.current,
    isConnected,
  };
};

// Core
import styled from 'styled-components';

// Component
import { OptionsSelectProps } from './OptionsSelect.types';

export const Container = styled.ul<Pick<OptionsSelectProps, 'open'>>`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.optionsSelect.optionsSelectBorder};
  box-shadow: 0 7px 14px 0 ${({ theme }) => theme.color.optionsSelect.optionsSelectBoxShadow};
  padding: 2px 4px;
  margin: 0;
  overflow: hidden;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  max-height: 0;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.color.optionsSelect.optionsSelectBackground};
  ${({ open }) => open && `
    max-height: 27.857em;
    visibility: visible;
  `};

  /* Hide scrollbar in Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  /* Hide scrollbar in Microsoft Edge and IE */
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
  };
`;

export const OptionsList = styled.li`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  margin: 2px 0;
  border-radius: 4px;
  transition: background-color 0.3s;
  color: ${({ theme }) => theme.color.optionsSelect.selectedOptionColor};
  gap: 10px;

  :hover {
    background-color:${({ theme }) => theme.color.optionsSelect.optionsBackgroundHover};
  };
`;

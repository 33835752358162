// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// Plugins
import { roundNumber } from '../../../../../plugins/general';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';

// Components
import {
  LineChart,
  LineChartType,
  Series,
} from '../../../../../layout/elements/LineChart';

// View
import { TrendingsHydraulicUnitProps } from './TrendingsHydraulicUnit.types';

type FilteredData = {
  trampPressure: number | null;
  clampPressure: number | null;
  readAt: number | null;
}[];

function TrendingsHydraulicUnit({
  className,
  testId,
  unitData,
}: TrendingsHydraulicUnitProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const series: Series[] = [
    {
      name: t('view.crusher.hydraulic.trampPressure'),
      field: 'trampPressure',
      type: LineChartType.line,
      date: 'readAt',
      color: 'green',
      unit: 'psi',
      bullet: true,
    },
    {
      name: t('view.crusher.hydraulic.clampPressure'),
      field: 'clampPressure',
      type: LineChartType.line,
      date: 'readAt',
      color: 'blue',
      unit: 'psi',
      bullet: true,
    },
  ];

  const filteredData: FilteredData = !_.isNil(unitData)
    ? unitData.map((data: EquipmentUnitDataCrusher) => ({
      trampPressure: roundNumber(data.hydraulicTrampPressure, 2),
      clampPressure: roundNumber(data.hydraulicClampPressure, 2),
      readAt: data.readAt,
    }))
    : [];

  return (
    <LineChart
      testId={testId}
      className={className}
      id="hydraulic-unit"
      series={series}
      data={filteredData}
      timeUnit="second"
      activeBullet
    />
  );
}

export { TrendingsHydraulicUnit };

// Core
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { InputRef } from 'antd';

// Component
import { InputProps, InputType } from './Input.types';
import {
  Container,
  Element,
  ElementTypePassword,
  Label,
  SupportingText,
} from './Input.style';

// Components
import { Paragraph, ParagraphSize } from '../Paragraph';

// Types
import { Theme } from '../../../types/theme';

// Plugins
import {
  formatNumber,
  parseLocaleNumber,
  roundNumber,
} from '../../../plugins/general';

// Locale
import { Language } from '../../../locale/i18n';

function Input({
  className,
  decimalPlaces,
  disabled = false,
  error = false,
  label,
  maxLength = 100,
  maxValue = Infinity,
  minValue = -Infinity,
  onChange,
  onPressEnter,
  placeholder,
  prefix,
  suffix,
  supportingText,
  testId,
  type = InputType.text,
  value,
}: InputProps): JSX.Element {
  // Dependencies
  const theme: Theme = useTheme();
  const { i18n } = useTranslation();

  // Refs
  const prefixRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const inputRef: React.MutableRefObject<InputRef | null> = useRef(null);
  const passwordInputRef: React.MutableRefObject<InputRef | null> = useRef(null);
  const onChangeRef: React.MutableRefObject<
  (value: string | number | null | undefined) => void
  > = useRef(onChange);

  // Validation
  if (type === InputType.password && suffix) {
    throw new Error('Input of type password cannot have a suffix');
  }
  if (
    type === InputType.number
    && typeof value !== 'number'
    && !_.isNil(value)
  ) {
    throw new Error('Input of type number must have a number value');
  }
  if (
    (type === InputType.text || type === InputType.password)
    && typeof value !== 'string'
    && !_.isNil(value)
  ) {
    throw new Error(`Input of type ${type} must have a string value`);
  }

  /* ***********************************************************************************************
  ************************************* INITIAL STATE **********************************************
  *********************************************************************************************** */

  interface InputState {
    focused: boolean;
    hovered: boolean;
    prefixWidth: number;
    internalValue: string;
  }

  const initialState: InputState = {
    focused: false,
    hovered: false,
    prefixWidth: 0,
    internalValue: '',
  };

  const [state, setState] = useState<InputState>(initialState);

  /* ***********************************************************************************************
  ***************************************** METHODS ************************************************
  *********************************************************************************************** */

  /**
   * Filters a numeric string value based on the provided locale.
   *
   * This function removes any characters that are not digits, commas, periods, or hyphens.
   * It also ensures that only one comma or period is present in the string, depending on the locale
   *
   * @param {string} newValue - The new value to be filtered.
   * @param {Language} locale - The locale to be used for filtering.
   * @returns {string} - The filtered numeric string value.
   */
  const filterNumberValue = (newValue: string, locale: Language): string => {
    let filteredNumberValue: string = newValue.replace(/[^0-9.,-]/g, '').replace(/(?!^)-/g, '');

    if (locale === Language.pt_BR) {
      filteredNumberValue = filteredNumberValue.replace(
        /,/g,
        (_match, offset, string) => (string.indexOf(',') === offset ? ',' : ''),
      );
    } else if (locale === Language.en_US) {
      filteredNumberValue = filteredNumberValue.replace(
        /\./g,
        (_match, offset, string) => (string.indexOf('.') === offset ? '.' : ''),
      );
    }

    return filteredNumberValue;
  };

  /**
   * Handles changes in the input field.
   * This function is triggered when the value of the input field changes.
   * It parses the new value based on the input type and updates the internal state.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event from the input field.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue: string = e.target.value;
    let parsedValue: string;

    if (type === InputType.number) {
      parsedValue = filterNumberValue(newValue, i18n.language as Language);
    } else { // InputType.text|password
      parsedValue = newValue;
      onChange(parsedValue);
    }

    setState((prevState) => ({ ...prevState, internalValue: parsedValue }));
  };

  /**
   * Handles the blur event for the input field.
   * Triggered when the input field loses focus. Processes the input value based on the input type.
   */
  const handleBlur = (): void => {
    if (type === InputType.number) {
      try {
        // Try to convert the value to a number
        let convertedNumber: number = parseLocaleNumber(
          state.internalValue,
          i18n.language.replace('_', '-'),
        );

        // Handle min and max values (optional)
        if (!_.isNil(minValue)) convertedNumber = Math.max(convertedNumber, minValue);
        if (!_.isNil(maxValue)) convertedNumber = Math.min(convertedNumber, maxValue);

        // Handle decimal places (optional)
        if (!_.isNil(decimalPlaces)) {
          convertedNumber = roundNumber(convertedNumber, decimalPlaces) as number;
        }

        // Feedback of possible number adjustments
        if (!Number.isNaN(convertedNumber)) {
          onChange(convertedNumber);
          setState({
            ...state,
            internalValue: formatNumber(convertedNumber, decimalPlaces),
            focused: false,
          });
        } else {
          onChange(null);
          setState({ ...state, internalValue: '', focused: false });
        }
      } catch (convertError) {
        // Error converting value
        onChange(null);
        setState({ ...state, internalValue: '', focused: false });
      }
    } else if (state.internalValue.trim() !== '') {
      const filteredValue: string = state.internalValue.trim();
      onChange(filteredValue);
      setState({ ...state, internalValue: filteredValue, focused: false });
    } else {
      onChange(null);
      setState({ ...state, internalValue: '', focused: false });
    }
  };

  // Render the prefix element
  const renderPrefix = (): JSX.Element | null => (
    prefix && React.isValidElement(prefix)
      ? (<div ref={prefixRef}>{prefix}</div>)
      : null
  );

  /**
   * Determines the color of the supporting text based on the input state.
   * Returns the appropriate color for the supporting text depending on the state of the input.
   * @returns {string} The color of the supporting text.
   */
  const getSupportingTextColor = (): string => {
    let color: string;

    if (disabled) {
      color = theme.color.input.disabledSupportingTextTextColor;
    } else if (error) {
      color = theme.color.input.errorSupportingTextTextColor;
    } else if (state.hovered) {
      color = theme.color.input.hoverSupportingTextTextColor;
    } else if (state.focused) {
      color = theme.color.input.focusedSupportingTextTextColor;
    } else {
      color = theme.color.input.defaultSupportingTextTextColor;
    }

    return color;
  };

  /* ***********************************************************************************************
  **************************************** COMPONENT HANDLING **************************************
  *********************************************************************************************** */

  // Get the width of the prefix element
  useEffect((): void => {
    if (prefixRef.current) {
      setState((prevState) => ({
        ...prevState,
        prefixWidth: prefixRef.current!.offsetWidth,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        prefixWidth: 0,
      }));
    }
  }, [prefix]);

  useEffect((): void => {
    // Force update component when language changes
  }, [i18n.language]);

  // Parse value property
  useEffect((): void => {
    let parsedValue: string;

    if (_.isNil(value)) {
      parsedValue = '';
    } else {
      if (type === InputType.number) {
        let adjustedNumber: number = value as number;

        // Handle decimal places (optional)
        if (!_.isNil(decimalPlaces)) {
          adjustedNumber = roundNumber(value as number, decimalPlaces) as number;
        }

        // Handle min and max values (optional)
        if (!_.isNil(minValue)) {
          adjustedNumber = Math.max(adjustedNumber, minValue);
        }
        if (!_.isNil(maxValue)) {
          adjustedNumber = Math.min(adjustedNumber, maxValue);
        }

        // Use a ref to avoid triggering re-renders
        onChangeRef.current(adjustedNumber);

        parsedValue = formatNumber(adjustedNumber, decimalPlaces);
      } else {
        parsedValue = value as string;
      }

      setState((prevState) => ({ ...prevState, internalValue: parsedValue }));
    }
  }, [
    decimalPlaces,
    i18n.language,
    maxValue,
    minValue,
    type,
    value,
  ]);

  useEffect((): void => {
    onChangeRef.current = onChange;
  }, [onChange]);

  return (
    <Container
      className={className}
      disabled={disabled}
      error={error}
      onMouseEnter={() => setState((prevState) => ({ ...prevState, hovered: true }))}
      onMouseLeave={() => setState((prevState) => ({ ...prevState, hovered: false }))}
    >
      {type === InputType.password ? (
        <ElementTypePassword
          data-testid={testId}
          disabled={disabled}
          error={error.toString()}
          label={label}
          maxLength={maxLength}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={() => setState((prevState) => ({ ...prevState, focused: true }))}
          onPressEnter={onPressEnter}
          prefix={renderPrefix()}
          ref={passwordInputRef}
          value={state.internalValue}
        />
      ) : (
        <Element
          data-testid={testId}
          disabled={disabled}
          error={error.toString()}
          label={label}
          maxLength={maxLength}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={() => setState((prevState) => ({ ...prevState, focused: true }))}
          onPressEnter={onPressEnter}
          placeholder={placeholder}
          prefix={renderPrefix()}
          ref={inputRef}
          suffix={suffix}
          type="text"
          value={state.internalValue}
        />
      )}
      {label && (
        <Label
          error={error}
          disabled={disabled}
          focused={state.focused}
          filled={state.internalValue !== ''}
          prefixWidth={state.prefixWidth}
        >
          {label}
        </Label>
      )}
      {supportingText && (
        <SupportingText disabled={disabled}>
          <Paragraph
            size={ParagraphSize.sm}
            color={getSupportingTextColor()}
          >
            {supportingText}
          </Paragraph>
        </SupportingText>
      )}
    </Container>
  );
}

export { Input };

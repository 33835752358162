// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// Layout
import {
  LineChart,
  LineChartType,
  Series,
} from '../../../../../layout/elements/LineChart';

// Plugins
import { roundNumber } from '../../../../../plugins/general';

// View
import { TrendingsPIDProps } from './TrendingsPID.types';
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';

type FilteredData = {
  currentCavityLevel: number | null;
  currentCrusherAmps: number | null;
  readAt: number | null;
  // currentCrusherSP: number; @TODO: apply after first release
  // cavityLevelSP: number | null; // @TODO: apply after first release
  // feedSpeed: number | null; // @TODO: Need to apply after first release
};

function TrendingsPID({
  testId,
  className,
  unitData,
}: TrendingsPIDProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const series: Series[] = [
    {
      name: t('view.crusher.trendings.pid.legend.currentCrusherAmps'),
      field: 'currentCrusherAmps',
      type: LineChartType.line,
      date: 'readAt',
      unit: 'A',
      color: 'green',
      bullet: true,
    },
    {
      name: t('view.crusher.trendings.pid.legend.currenCavityLevel'),
      field: 'currentCavityLevel',
      type: LineChartType.line,
      date: 'readAt',
      unit: '%',
      opposite: true,
      color: 'blue', // @TODO Dani change the color?
      bullet: true,
    },
    // { // @TODO: apply the dynamic value after first release
    //   name: t('view.crusher.trendings.pid.legend.currentCrusherSP'),
    //   field: 'currentCrusherSP',
    //   type: LineChartType.line,
    //   date: 'readAt',
    //   unit: 'A',
    //   color: 'yellow',
    //   bullet: true,
    // },
    // { // @TODO: apply the dynamic after first release
    //   name: t('view.crusher.trendings.pid.legend.cavityLevelSP'),
    //   field: 'cavityLevelSP',
    //   type: LineChartType.line,
    //   date: 'readAt',
    //   unit: '%',
    //   opposite: true,
    //   color: 'orange',
    //   bullet: true,
    // },
    // {
    //   name: t('view.crusher.trendings.pid.legend.feedSpeed'),
    //   field: 'feedSpeed',
    //   type: LineChartType.line,
    //   date: 'readAt',
    //   unit: '%',
    //   opposite: true,
    //   color: 'green',
    //   bullet: true,
    // },
  ];

  const filteredData: FilteredData[] = !_.isNil(unitData)
    ? unitData.map((data: EquipmentUnitDataCrusher) => ({
      currentCrusherAmps: roundNumber(data.crusherMotorAmps, 2),
      currentCavityLevel: roundNumber(data.cavityLevel, 2),
      readAt: data.readAt,
      // currentCrusherSP: 300, // @TODO: apply the dynamic value after first release
      // cavityLevelSP: roundNumber(data.cavityLevel, 2), // @TODO: apply after first release
      // feedSpeed: roundNumber(data.feedSpeed, 2), // @TODO: Need to apply after first release
    }))
    : [];

  return (
    <LineChart
      testId={testId}
      className={className}
      id="PID-trending"
      series={series}
      data={filteredData}
      timeUnit="second"
      activeBullet
    />
  );
}

export { TrendingsPID };

// Core
import React from 'react';

function Icon() {
  return (
    <svg
      data-testid="check-icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66665 10.1144L12.7946 3.98569L13.738 4.92835L6.66665 11.9997L2.42398 7.75702L3.36665 6.81435L6.66665 10.1144Z"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;

// Core
import React, {
  useCallback,
  useState,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

// Components
import { ArrowDropUpLine } from '../../icons';
import { OptionSelect, OptionsSelect } from '../OptionsSelect';
import { Paragraph, ParagraphSize } from '../Paragraph';

// Component
import { PaginationSizeSelectorProps } from './PaginationSizeSelector.types';
import {
  Container,
  Element,
  PaginationButton,
  Select,
} from './PaginationSizeSelector.style';

// Types
import { Theme } from '../../../types/theme';

function PaginationSizeSelector({
  className,
  onSelectPageSize,
  pageSizeOptions,
  pageSizeIndexDefault,
  testId,
  totalItems,
}: PaginationSizeSelectorProps): JSX.Element {
  // Dependencies
  const theme: Theme = useTheme();
  const { t } = useTranslation();

  // Validation
  const validatedPageSizeIndexDefault: number = (
    (pageSizeIndexDefault >= 0 && pageSizeIndexDefault < pageSizeOptions.length)
      ? pageSizeIndexDefault
      : pageSizeOptions.length - 1
  );

  /* ***********************************************************************************************
  ************************************ INITIAL STATES **********************************************
  *********************************************************************************************** */

  const initialState: {
    openSelect: boolean,
    pageSize: string,
  } = {
    openSelect: false,
    pageSize: pageSizeOptions[validatedPageSizeIndexDefault].toString(),
  };

  const [state, setState] = useState(initialState);

  /* ***********************************************************************************************
  ***************************************** METHODS ************************************************
  *********************************************************************************************** */

  // Convert pageSizeOptions to OptionsSelect
  const convertOptionsToOptionsSelect: OptionSelect[] = pageSizeOptions.map((
    value: number,
  ) => ({
    key: value.toString(),
    text: value.toString(),
  }));

  const setOpenSelect = (openSelect: boolean): void => {
    setState((prevState) => ({ ...prevState, openSelect }));
  };

  const setPageSize = useCallback((pageSize: string): void => {
    setState((prevState) => ({ ...prevState, pageSize }));
    onSelectPageSize(Number(pageSize));
  }, [onSelectPageSize]);

  return (
    <Container className={className} data-testid={testId}>
      <Paragraph size={ParagraphSize.sm} color={theme.color.paginationSizeSelector.textColor}>
        {t('layout.elements.paginationSizeSelector.items', { totalItems })}
      </Paragraph>
      <Element>
        <Select>
          <OptionsSelect
            open={state.openSelect}
            options={convertOptionsToOptionsSelect}
            selected={state.pageSize}
            onSelect={(id) => {
              if (id !== null) {
                const parsedId = Number(id);
                if (!Number.isNaN(parsedId)) {
                  setPageSize(id.toString());
                  setOpenSelect(false);
                }
              }
            }}
            onClose={() => setOpenSelect(false)}
          />
        </Select>
        <PaginationButton
          block={false}
          onClick={() => setOpenSelect(!state.openSelect)}
          open={state.openSelect}
        >
          {t('layout.elements.paginationSizeSelector.page', { pageSize: state.pageSize })}
          <ArrowDropUpLine />
        </PaginationButton>
      </Element>
    </Container>
  );
}

export { PaginationSizeSelector };

// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// Plugins
import { roundNumber } from '../../../../../plugins/general';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';

// Components
import {
  LineChart,
  LineChartType,
  Series,
} from '../../../../../layout/elements/LineChart';

// View
import { TrendingsMotorCurrentProps } from './TrendingsMotorCurrent.types';

type FilteredData = {
  motorCurrent: number | null;
  readAt: number | null;
}[];

function TrendingsMotorCurrent({
  className,
  testId,
  unitData,
}: TrendingsMotorCurrentProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const series: Series[] = [
    {
      name: t('view.crusher.trendings.motorCurrent.title'),
      field: 'motorCurrent',
      type: LineChartType.line,
      date: 'readAt',
      color: 'orange',
      unit: 'A',
      bullet: true,
    },
  ];

  const filteredData: FilteredData = !_.isNil(unitData)
    ? unitData.map((data: EquipmentUnitDataCrusher) => ({
      motorCurrent: roundNumber(data.crusherMotorAmps, 2),
      readAt: data.readAt,
    }))
    : [];

  return (
    <LineChart
      testId={testId}
      className={className}
      id="motor-current"
      series={series}
      data={filteredData}
      timeUnit="second"
      activeBullet
    />
  );
}

export { TrendingsMotorCurrent };

// Core
import React from 'react';

// Components
import { MainNavigation } from '../MainNavigation';
import { PaginationFooter } from '../elements/PaginationFooter';

// Component
import { CustomerPanelProps } from './CustomerPanel.types';
import { Layout, MainNavigationContainer } from './CustomerPanel.style';

function CustomerPanel({
  testId,
  className,
  children,
  footer,
}: CustomerPanelProps): JSX.Element {
  return (
    <Layout
      data-testid={testId}
      className={className}
    >
      <MainNavigationContainer>
        <MainNavigation testId="main-navigation" />
      </MainNavigationContainer>
      {children}
      {footer && (
        <PaginationFooter
          totalItems={footer.totalItems}
          currentPage={footer.currentPage}
          testId="pagination-footer"
          onPageChange={footer.onPageChange}
        />
      )}
    </Layout>
  );
}

export { CustomerPanel };

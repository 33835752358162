// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// Plugins
import { roundNumber } from '../../../../../plugins/general';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';

// Components
import {
  LineChart,
  LineChartType,
  Series,
} from '../../../../../layout/elements/LineChart';

// View
import { TrendingsRingBounceProps } from './TrendingsRingBounce.types';

type FilteredData = {
  vibrationSensor1: number | null;
  vibrationSensor2: number | null;
  vibrationSensor3: number | null;
  readAt: number | null;
}[];

function TrendingsRingBounce({
  className,
  testId,
  unitData,
}: TrendingsRingBounceProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const DEFAULT_TRANSLATE_LEGEND: string = 'view.crusher.trendings.ringBounceSensor';

  const series: Series[] = [
    {
      name: t(`${DEFAULT_TRANSLATE_LEGEND}.legend.vibrationSensor1`),
      field: 'vibrationSensor1',
      type: LineChartType.line,
      date: 'readAt',
      color: 'lightPink',
      unit: '%',
      bullet: true,
    },
    {
      name: t(`${DEFAULT_TRANSLATE_LEGEND}.legend.vibrationSensor2`),
      field: 'vibrationSensor2',
      type: LineChartType.line,
      date: 'readAt',
      color: 'lightGreen',
      unit: '%',
      bullet: true,
    },
    {
      name: t(`${DEFAULT_TRANSLATE_LEGEND}.legend.vibrationSensor3`),
      field: 'vibrationSensor3',
      type: LineChartType.line,
      date: 'readAt',
      color: 'blue',
      unit: '%',
      bullet: true,
    },
    // {
    //   name: t(`${DEFAULT_TRANSLATE_LEGEND}.legend.maxVibration`),
    //   field: 'maxVibration',
    //   type: LineChartType.line,
    //   date: 'readAt',
    //   color: 'orange',
    //   unit: '%',
    // },
  ];

  const filteredData: FilteredData = !_.isNil(unitData)
    ? unitData.map((data: EquipmentUnitDataCrusher) => ({
      vibrationSensor1: roundNumber(data.vibrationSensor1, 2),
      vibrationSensor2: roundNumber(data.vibrationSensor2, 2),
      vibrationSensor3: roundNumber(data.vibrationSensor3, 2),
      // maxVibration: roundNumber(data.maxVibration, 2), // @TODO: apply after first release
      readAt: data.readAt,
    }))
    : [];

  return (
    <LineChart
      testId={testId}
      className={className}
      id="ring-bounce"
      series={series}
      data={filteredData}
      timeUnit="second"
      activeBullet
    />
  );
}

export { TrendingsRingBounce };

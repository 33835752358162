export enum OptionsSelectMode {
  default = 'default',
  multiple = 'multiple',
}

export interface OptionSelect {
  key: string | number;
  text: string | number;
}

export interface OptionsSelectProps {
  className?: string;
  mode?: OptionsSelectMode;
  onClose: (open: boolean) => void;
  onSelect: (selected: OptionsSelectProps['selected']) => void;
  open: boolean;
  options: OptionSelect[];
  selected: string | number | Array<string | number> | null;
  testId?: string;
}

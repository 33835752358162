// Libraries
import axios, { AxiosResponse } from 'axios';
import i18n, { TFunction } from 'i18next';

// Store
import { getAuthToken, setAuthToken } from '../store/session.store';

// Layout
import { showToast, ToastType } from '../layout/elements/Toast';

export enum RequestMethod {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

export interface VantageApiRequestPayload {
  apiName: VantageApi;
  auth?: boolean;
  body?: Record<string, any>;
  path?: string;
  method?: RequestMethod;
  params?: Record<string, any>;
  handleErrors?: boolean;
  contentType?: string;
}

export interface HTTPResponseError extends Error {
  response: {
    status: number;
    data: string;
  };
}

export type VantageApi = ('customer' | 'equipmentModel' | 'equipmentUnit'
| 'equipmentUnitDataMonitoring' | 'plant' | 'users');

export const isHTTPResponseError = (error: any): error is HTTPResponseError => (
  error && error.response && error.response.status && typeof error.response.data === 'string'
);

const apiBaseUrls: Record<VantageApi, string> = {
  customer: String(process.env.REACT_APP_CUSTOMER_API),
  equipmentModel: String(process.env.REACT_APP_EQUIPMENT_MODEL_API),
  equipmentUnit: String(process.env.REACT_APP_EQUIPMENT_UNIT_API),
  equipmentUnitDataMonitoring: String(process.env.REACT_APP_EQUIPMENT_UNIT_DATA_MONITORING_API),
  plant: String(process.env.REACT_APP_PLANT_API),
  users: String(process.env.REACT_APP_USER_API),
};

const handleRequestError = (error: any, t: TFunction): void => {
  const errorMessages: Record<number, string> = {
    403: t('common.forbiddenError'),
    404: t('common.notFoundError'),
    500: t('common.defaultError'),
  };

  // Redirect to Login on 401
  if (error.response && error.response.status === 401) {
    setAuthToken(null);
    window.location.href = '/';
  }

  if (error.response) {
    const errorResponse = error.response.status === 422
      ? error.response.data
      : (errorMessages[error.response.status] || t('common.defaultError'));

    showToast({
      type: ToastType.error,
      text: `${errorResponse}`,
    });
  } else if (error.message === 'Network Error') {
    showToast({
      type: ToastType.error,
      text: `${t('common.networkError')}`,
    });
  } else {
    showToast({
      type: ToastType.error,
      text: `${t('common.defaultError')}`,
    });
  }
};

export async function performVantageApiRequest(
  payload: VantageApiRequestPayload,
): Promise<any> {
  const {
    apiName,
    auth = true,
    body,
    path = '/',
    method = RequestMethod.GET,
    params,
    handleErrors = true,
    contentType = 'application/json',
  } = payload;

  // Dependencies
  const { t } = i18n;

  // Store
  const authToken = getAuthToken();

  // Headers with Bearer Token and content type
  const headers: Record<string, string> = {
    'content-type': contentType,
  };
  if (auth && authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  // Get the base URL based on the apiName
  const baseUrl: string = apiBaseUrls[apiName];

  // Construct the full URL by combining the base URL and path
  const apiUrl: string = `${baseUrl}${path}`;

  // Response with parameters
  try {
    const response: AxiosResponse<string, any> = await axios(
      apiUrl,
      {
        method,
        data: body,
        headers,
        params,
      },
    );
    return response.data;
  } catch (error: unknown) {
    if (handleErrors) {
      handleRequestError(error, t);
    }
    throw error;
  }
}

const createApiRequestFunction = (apiName: VantageApi) => (
  ({
    auth,
    body,
    contentType,
    handleErrors,
    method,
    params,
    path,
  }: Omit<VantageApiRequestPayload, 'apiName'>): Promise<any> => performVantageApiRequest({
    apiName,
    auth,
    body,
    contentType,
    handleErrors,
    method,
    params,
    path,
  })
);

// The function to use the APIs
export const requestVantageApi = {
  customer: createApiRequestFunction('customer'),
  equipmentModel: createApiRequestFunction('equipmentModel'),
  equipmentUnit: createApiRequestFunction('equipmentUnit'),
  equipmentUnitDataMonitoring: createApiRequestFunction('equipmentUnitDataMonitoring'),
  plant: createApiRequestFunction('plant'),
  users: createApiRequestFunction('users'),
};

// Core
import React from 'react';

// Component
import { Container } from './Pagination.style';
import { PaginationProps } from './Pagination.types';

function Pagination({
  className,
  current,
  onPageChange,
  pageSize,
  testId,
  totalItems,
}: PaginationProps): JSX.Element {
  return (
    <Container
      className={className}
      current={current}
      data-testid={testId}
      onChange={onPageChange}
      pageSize={pageSize}
      showSizeChanger={false}
      total={totalItems}
    />
  );
}

export { Pagination };
